
section.services-section {
    @include media-breakpoint-up(xl) {
        & {
            padding: 200px 0;
        }
    }
    @include media-breakpoint-down(lg) {
        & {
            padding: 100px 0;
        }
    }
    .service-headers {
        margin-right: 70px;
    }
    .service-headers a {
        color: rgba($color: #202422, $alpha: 0.51);
        cursor: pointer;
        display: block;
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 0.12em;
        font-family: 'proxima-nova', sans-serif;
        margin-bottom: 10px;
        padding: 20px 35px;
        font-weight: 600;
        &.active {
            color: #fff;
            background-color: #3EA28B;
        }
        &:hover {
            color: #fff;
            background-color: #3EA28B;
        }
        
        @include media-breakpoint-down(md) { 
            & {
                margin-bottom: 15px;
            }
        }
    }

    p.display-3 {
        color: #202422;
    }
    .service-btn {
        font-size: 15px;
        letter-spacing: 0.12em;

        svg {
            font-size: 10px;
            position: relative;
            top: -1px;
            color: #3EA28B;
        }
    }
    
    .service-descriptions {
        div[data-service-description-num] {
            position: relative;
            transition: .7s ease!important;
            visibility: hidden;
            opacity: 0;
            top: 20px;
            &.active {
                transition: .7s ease !important;
                visibility: visible;
                opacity: 1;
                top: 0px;
                p, span {
                    display: block !important;
                }
            }
            span {
                display: none !important;
            }
            p {
                display: none !important;
                color: #4B5250;
                font-size: 20px;
                font-weight: 300;
                line-height: 34px;
            }
        }
    }
    
    span.btn-wrap {
        .btn {
            max-width: 245px !important;
            width: 100%;
            @include media-breakpoint-down(lg) {
                & {
                    max-width: 230px !important;
                }
            }
        }
        .btn-ghost {
            border: 1px solid $primary !important;
            background: $gray-900;
            background: $white;
        }
    }
    &.no-testimonials { 
        background: #34302a;
        padding: 100px 0px !important;
        p.display-3 {
            color: #fff !important;
        }
        .service-headers a {
            color: #fff !important;
            &.active {
                color: darken(#fff, 20%) !important;
            }
        }
        .service-descriptions {
            p {
                color: #fff !important;
            }
        }
        span.btn-wrap .btn-ghost {
            background: #34302a !important;
        }
    }
    
}