section.testimonials-section {

    .panel-image {
        @media screen and (max-width: 576px) {
            & {
                margin-left: 0;
                padding: 65px 15px 25px;
            }
        }
    }

&.testimonials {
    blockquote {
        background-color: #3EA28B;
        padding: 85px 70px;
        min-height: 400px;
        display: flex;
        flex-direction: column;

        p {
            order: 2;
            font-family: 'proxima-nova', sans-serif !important;
            @include media-breakpoint-up(xxl) {
                & {
                    font-size: 19px;
                    line-height: 34px;
                }
            }
            @include media-breakpoint-down(xxl) {
                font-size: 17px;
                line-height: 30px;
            }
        }
        

        footer {
            order: 1;
            margin-bottom: 20px;
            cite {
                span {
                    letter-spacing: 0.12em!important;
                }
            }
        }
    }
    .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
        font-size: 23px !important;

        span {
            display: none;
        }
    }
    .owl-prev::after {
        font-family: "Font Awesome 5 Pro";
        content: "\f060";
        font-size: 23px;
    }
    .owl-next::after {
        font-family: "Font Awesome 5 Pro";
        content: "\f061";
        font-size: 23px;
    }
    .owl-carousel .owl-nav button.owl-next {
        color: #3EA28B !important;
        right: 24px;
    }
    .owl-carousel .owl-nav button.owl-next:hover {
        color: #4cc5a9 !important;
    }
    .owl-carousel .owl-nav button.owl-prev {
        color: rgba($color: #F4F6F5, $alpha: 0.52) !important;
    }
    .owl-carousel .owl-nav button.owl-prev:hover {
        color: rgba($color: #F4F6F5, $alpha: 0.65) !important;
    }
    .testimonial-wrapper {
        position: relative;
        right: -30%;
        border-right: none !important;
        @media screen and (max-width: 991px) {
            & {
                margin-bottom: 0px !important;
                margin-top: -85px !important;
            }
        }
        @include media-breakpoint-up(xxl) {
            & {
                margin-bottom: 45px;
                padding: 50px 0px;
            }
        }
        @include media-breakpoint-down(xl) {
            & {
                margin-bottom: -200px;
                padding: 40px 55px;
            }
        }
        @include media-breakpoint-down(sm) {
            & {
                padding: 40px 25px;
            }
        }

    }
}
    
}